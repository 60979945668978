:root {
    --logo-text-color: #72F2EB;
    --save-button-color: #46D469;
    --edit-button-color: #1B7F79;
    --raised-button-color: #00CCC0;
    --cancel-button-color: #747F7F;
    --bright-accent: #CD0000;
    --warning-color: #ECE84E;
    --table-header-background: #A6CCFF;
    --table-background-color: #E8F2FF;
    --toolbar-background: #215DAB;
    --toolbar-text-color: #2B2945;
    --card-background-color: #f3f3f3;
    --menu-item-color: #F0F5FB;
    --expansion-background-color: #FFFFEB;
    --form-field-background: #FFFED4;
    --card-button-button1: #00CCC0;
    --card-button-button2: #1B7F79;
}
/* AMI blue: #215DAB; */
